import { getAxiosWithAuthorization } from '@clatter/platform';

const pageImagesApi = {
  fetchImagesList: (documentId) =>
    getAxiosWithAuthorization({
      url: `/summary/documents/${documentId}/pageimages`,
      method: 'GET',
    }),
  fetchImage: (documentId, image) =>
    getAxiosWithAuthorization({
      url: `/summary/documents/${documentId}/images/${image}`,
      method: 'GET',
      responseType: 'blob',
    }),
};

export default pageImagesApi;
