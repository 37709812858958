import queryString from 'query-string';
import { getAxiosApiV2WithAuthorization, getAxiosWithAuthorization } from '@clatter/platform';
import { awsService } from '@clatter/ui';

const templatesApi = {
  fetchTemplates: (params) =>
    getAxiosWithAuthorization({
      url: `/summary/templates`,
      method: 'GET',
      params: params,
    }),
  deleteTemplate: ({ templateId }) =>
    getAxiosWithAuthorization({
      url: `/summary/templates/${templateId}`,
      method: 'DELETE',
    }),
  cloneTemplate: ({ templateId }) =>
    getAxiosWithAuthorization({
      url: `/summary/templates/${templateId}`,
      method: 'POST',
    }),
  fetchTemplate: ({ templateId }) =>
    getAxiosWithAuthorization({
      url: `/summary/templates/${templateId}`,
      method: 'GET',
    }),
  updateTemplate: ({ templateId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/templates/${templateId}`,
      method: 'PATCH',
      data: data,
    }),
  createTemplate: (data) =>
    getAxiosWithAuthorization({
      url: 'summary/templates',
      method: 'POST',
      data: data,
    }),
  fetchBuiltInBlocks: () =>
    getAxiosWithAuthorization({
      url: '/summary/blocks/?builtin=true',
      method: 'GET',
    }),
  fetchCustomBlocks: () =>
    getAxiosWithAuthorization({
      url: '/summary/blocks',
      method: 'GET',
    }),
  updateBlock: ({ blockId, data, templateId }) =>
    getAxiosWithAuthorization({
      url: queryString.stringifyUrl({
        url: `/summary/blocks/${blockId}`,
        query: { templateId },
      }),
      method: 'PATCH',
      data,
    }),
  createBlock: ({ data, templateId }) =>
    getAxiosWithAuthorization({
      url: queryString.stringifyUrl({
        url: `/summary/blocks`,
        query: { templateId },
      }),
      method: 'POST',
      data,
    }),
  uploadTemplateFileToS3: async ({
     file,
     overrideFileType,
     templateId,
   },
   onUploadProgress,) => {
    let dataToUpload = {
      file_name: file.name,
    };
    const { data: presignedUrl } = await getAxiosApiV2WithAuthorization({
      url: `/templates/${templateId}/files`,
      method: 'POST',
      data: dataToUpload,
      params: {
        templateId,
      },
    });
    return await awsService.putFile(presignedUrl, file, onUploadProgress);
  },
  uploadDocumentToS3: async (
    {
      blockId,
      file,
      layout,
      pptxLayout,
      category,
      reusable,
      overrideFileType,
      templateId,
      templateThumbnail,
    },
    onUploadProgress,
  ) => {
    let dataToUpload = {
      name: file.name,
      fileName: file.name,
      fileType: overrideFileType || file.type,
    };

    if (layout) {
      dataToUpload.layout = layout;
    }

    if (pptxLayout) {
      dataToUpload.pptxLayout = pptxLayout;
    }

    if (category) {
      dataToUpload.category = category;
      dataToUpload.reusable = reusable;
    }

    if(templateThumbnail) {
      dataToUpload.templateThumbnail = true;
    }

    const {
      data: { presignedUrl },
    } = await getAxiosWithAuthorization({
      url: `/summary/blocks/${blockId}/files`,
      method: 'POST',
      data: dataToUpload,
      params: {
        templateId,
      },
    });

    return await awsService.putFile(presignedUrl, file, onUploadProgress);
  },
  fetchBlockFunction: () =>
    getAxiosWithAuthorization({ url: 'summary/blockfunction' }),
  fetchTemplateImages: ({ blockId, templateId }) =>
    getAxiosWithAuthorization({
      url: `summary/blocks/${blockId}/content?templateId=${templateId}&config=true`,
    }),
  deleteTemplateVariable: ({ blockId, templateId, variableId }) =>
    getAxiosWithAuthorization({
      url: `summary/blocks/${blockId}`,
      method: 'PATCH',
      params: {
        templateId,
        removeVariable: variableId,
      },
    }),
  deleteTemplateBlockFile: ({ blockId, templateId, fileId }) =>
    getAxiosWithAuthorization({
      url: `/summary/blocks/${blockId}`,
      method: 'DELETE',
      data: {
        templateId: templateId,
        _id: fileId,
      },
    }),
};

export default templatesApi;
