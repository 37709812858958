import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@clatter/ui';

const StyledCardHeader = styled.div.attrs((props) => ({
  padding: props.padding || '15px 30px',
}))`
  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey};
`;

const StyledTitle = styled.div`
  h4{
    margin-bottom: 0;
  }
`

const CardHeader = forwardRef(({ children, title, ...props }, ref) => (
  <StyledCardHeader ref={ref} {...props}>
    {title && (
      <StyledTitle>
        <Typography component='h4'>{title}</Typography>
      </StyledTitle>
    )}
    {children}
  </StyledCardHeader>
));

export default CardHeader;
