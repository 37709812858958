const routes = {
  index: '/',
  documents: '/documents',
  addDocument: '/documents/add',
  editDocument: '/document/:documentId',
  templates: '/templates',
  addTemplates: '/templates/add',
  buildTemplate: '/template/build/:id',
  reportDocumentsByUser: '/reports/documents-by-user',
  reportDocumentsDownloads: '/reports/documents-downloads',
  reportDocumentsCreate: '/reports/documents-create',
  reportTemplatesUsage: '/reports/template-usage',
  logout: '/logout',
};

export const documentTitleMap = {
  documents: 'Project Library',
  addDocument: 'Add Document',
  editDocument: 'Edit Document',
  templates: 'Templates',
  addTemplates: 'Add Template',
  editTemplate: 'Edit Template',
  reportDocumentsByUser: {
    document: 'Reports:Documents by User',
    page: 'Documents by User',
  },
  reportDocumentsDownloads: {
    document: 'Reports:Documents Downloaded',
    page: 'Documents Downloaded',
  },
  reportDocumentsCreate: {
    document: 'Reports:Documents Created',
    page: 'Documents Created',
  },
  reportDocumentsAssetUsage: {
    document: 'Reports:Asset Usage',
    page: 'Asset Usage',
  },
  reportTemplateUsage: {
    document: 'Reports:Template Usage',
    page: 'Template Usage',
  },
  reportTemplatesUnusedAssets: {
    document: 'Reports:Unused Assets',
    page: 'Unused Assets',
  },
};
export default routes;
