import { downloadButtonEntries, mimeTypes } from '@clatter/platform';

export const getBlockDisplayName = (name) => {
  switch (name) {
    case 'coverHtml':
      return 'Cover';
    case 'placemat1Side':
      return 'Side 1';
    case 'placemat2Sides':
      return 'Side 2';
    case 'orxPlacematSide1':
      return '';
    default:
      return name;
  }
};

export const isPptxBlockKeyExcluded = (key) =>
  ['headline', 'cobrand logo'].includes(key?.toLowerCase());

export const normalizePptxFormData = (initialFormState) => {
  return {
    ...initialFormState,
    contentElements: initialFormState.contentElements?.map?.((element) => ({
      ...element,
      metadata: {
        ...element?.metadata,
        textVariables: element?.metadata?.textVariables?.map(
          (textVariable) => ({
            ...textVariable,
            label: textVariable?.label || textVariable?.name,
            description: textVariable?.description || '',
            optional: isItemOptional(textVariable, true),
          }),
        ),
      },
    })),
    layouts: initialFormState?.layouts?.map((layoutData) => ({
      ...layoutData,
      metadata: {
        ...layoutData?.metadata,
        textVariables: layoutData?.metadata?.textVariables
          ?.filter((item) => !isPptxBlockKeyExcluded(item?.name))
          ?.map((textVariable) => ({
            ...textVariable,
            optional: isItemOptional(textVariable, true),
          })),
        shapeVariables: layoutData?.metadata?.shapeVariables?.map(
          (shapeVariable) => ({
            ...shapeVariable,
            optional: isItemOptional(shapeVariable),
          }),
        ),
      },
    })),
  };
};

const getTypeListValue = (item) => {
  if (item?.content) {
    return {
      label: item?.content,
      value: item?.content,
    };
  }
  if (!item?.content && item?.options?.includes('-- No headline --')) {
    return {
      label: '-- No headline --',
      value: '-- No headline --',
    };
  }
  if (item.defaultValue) {
    return {
      label: item?.defaultValue,
      value: item?.defaultValue,
    };
  }
  return {
    label: null,
    value: null,
  };
};

export const isItemOptional = (data, defaultValue = false) =>
  data?.hasOwnProperty('optional') ? data?.optional : defaultValue;

export const getBlockVariablesValues = (variables) =>
  variables.reduce((acc, item) => {
    if (!acc[item.name]) {
      switch (item?.type) {
        case 'list':
          acc[item.name] = getTypeListValue(item);
          break;
        default:
          acc[item.name] = item?.content || item?.defaultValue || null;
      }
    }
    return acc;
  }, {});

export const mapLayoutDataToForm = (layout) => {
  const contentData = (layout?.metadata?.shapeVariables || []).map((item) => ({
    contentElementId: '',
    name: item.name,
    variables: [],
  }));

  const textVariablesData = (layout?.metadata?.textVariables || [])
    ?.filter((item) => !isPptxBlockKeyExcluded(item?.name))
    ?.map((textVariable) => ({
      name: textVariable?.name,
      value: '',
      optional: isItemOptional(textVariable),
    }));

  const layoutHasHeadline = layout?.variables?.some(
    (item) => item?.name === 'headline',
  );

  return {
    layoutId: layout?._id,
    contentData,
    textVariablesData: layoutHasHeadline
      ? [...textVariablesData, { name: 'headline', value: '' }]
      : textVariablesData,
  };
};

export const getPptxBlockInitialData = ({ block }) => {
  let pages = [];

  if (block?.pages?.length > 0) {
    block?.pages.forEach((pageData, pageIndex) => {
      let updatedContent = [...pageData?.content];

      const defaultLayoutData = pageData?.layoutId
        ? block?.layouts?.find((layout) => layout?._id === pageData?.layoutId)
        : block?.layouts?.[0];

      if (!defaultLayoutData || !defaultLayoutData?._id) {
        console.debug('Error, no layout data provided!');
        return;
      }

      // set unsaved shapes to form so components could work properly
      defaultLayoutData?.metadata?.shapeVariables?.forEach(
        (initialShapeData) => {
          const shapeHasBeenSaved = !!block?.pages[pageIndex]?.content?.find(
            (item) => item?.name === initialShapeData?.name,
          );

          if (!shapeHasBeenSaved) {
            updatedContent.push({
              contentElementId: '',
              name: initialShapeData.name,
              variables: [],
            });
          }
        },
      );

      // set saved data for page
      pages[pageIndex] = {
        ...block?.pages[pageIndex],
        content: updatedContent,
      };
    });
  } else {
    // set initial data for a new pages
    const layoutData = mapLayoutDataToForm(block?.layouts?.[0] || []);

    pages = [
      {
        layoutId: layoutData?.layoutId,
        content: layoutData?.contentData,
        textVariables: layoutData?.textVariablesData,
      },
    ];
  }

  let blockInitialData = {
    pages: pages,
  };

  if (Array.isArray(block?.variables)) {
    const variablesValues = getBlockVariablesValues(block?.variables);

    blockInitialData = {
      ...blockInitialData,
      ...variablesValues,
    };
  }

  return blockInitialData;
};

export const getPreviewButtonMenuItems = (template) => {
  if (template?.mimeType === mimeTypes.PPTX) {
    return [downloadButtonEntries.PPTX, downloadButtonEntries.PDF];
  }
  if (
    template?.name === 'OE KIT' ||
    template?.mimeType === mimeTypes.ZIP
  ) {
    return [downloadButtonEntries.ZIP];
  }
  if (!template?.mimeType || template?.mimeType === mimeTypes.PDF) {
    return [downloadButtonEntries.PDF];
  }
};
