import React, { useMemo, useState } from 'react';
import {
  CloseOutlined as CloseOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';
import {
  Button,
  EditorControl,
  Checkbox,
  Dialog,
  IconButton,
  SelectControl,
  Table,
  TextControl,
  StyledWYSIWYGLabel,
} from '@clatter/ui';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledWysiwygEditForm = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;

  .wysiwyg-actions {
    display: flex;
    gap: 6px;
  }
`;

const StyledFormContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 2em;

  .item-selected {
    background-color: ${({ theme }) => theme.palette.grey};
  }

  .input-error {
    border-color: ${({ theme }) => theme.palette.error} !important;
  }

  input[type='text'] {
    min-width: 150px;
  }
`;
const StyledOptionsEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 320px;
  min-width: 450px;
  max-width: 550px;
  margin: auto;

  .option-dialog-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid lightgray;
    padding-top: 10px;
  }

  .input-error {
    border-color: ${({ theme }) => theme.palette.error} !important;
  }
`;

const StyledSelectContainer = styled.div`
  &.input-error {
    .react-select__control {
      border-color: red !important;
    }
  }
`;

const StyledOptionsList = styled.ul`
  margin: 0;
  padding: 5px;
  margin-bottom: 2em;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;

  li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid lightgray;

    .option-item-label {
      flex: 1;
      padding-right: 20px;
    }

    &:last-of-type {
      border: none;
    }
  }
  .item-selected {
    background-color: ${({ theme }) => theme.palette.grey};
  }
`;

const initialFormValues = {
  name: '',
  label: '',
  type: null,
  isHidden: false,
  isMandatory: false,
  readOnly: false,
  defaultValue: '',
  options: [],
};

const arrayToSting = (value = []) =>
  value.map((text, index) => (
    <StyledWYSIWYGLabel
      key={`${text}-${index}`}
      noWrap
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ));

const validateField = ({ value, checkAgainst }) => {
  return !!checkAgainst.includes(value?.toLowerCase()?.trim());
};

// necessary only if options are list of string
// ideally we should switch to the proper datamodel
// eg. { value: string, label: string }
const getOptionsFromString = (value = []) => {
  return value.map((option) => ({
    label: option,
    value: option,
  }));
};

const WysiwygForm = ({
  isNew,
  onChange,
  defaultValue,
  onCancel,
  onSubmit,
  disableSaveButton,
  disableRichText,
}) => (
  <StyledWysiwygEditForm>
    {disableRichText ? (
      <TextControl
        defaultValue={defaultValue}
        onChange={(e) => onChange(e?.target?.value)}
      />
    ) : (
      <EditorControl
        wysiwygOptions={['inline']}
        onChange={onChange}
        value={defaultValue}
      />
    )}
    <div className="wysiwyg-actions">
      <Button onClick={onSubmit} disabled={disableSaveButton} fullWidth>
        {isNew ? 'Add' : 'Update'}
      </Button>
      <Button onClick={onCancel} fullWidth>
        Cancel
      </Button>
    </div>
  </StyledWysiwygEditForm>
);

const MetadataEditor = ({
  onChange,
  enableSaml = false,
  enableReadOnly = false,
  value = [],
  disableRichText = false,
  customTypes = [],
}) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [labelError, setLabelError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [editOptionsList, setEditOptionsList] = useState(false);
  const [editedOptionItem, setEditedOptionItem] = useState('');
  const [editedOptionItemIndex, setEditedOptionItemIndex] = useState(null);
  const [editedOptionItemError, setEditedOptionItemError] = useState(false);
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);

  const isEditing = selectedIndex || selectedIndex === 0;

  const existingLabels = value
    ?.map((item) => item?.label?.toLowerCase())
    .filter((item) => item);

  const existingNames = value
    ?.map((item) => item?.name?.toLowerCase())
    .filter((item) => item);

  // this assumes that options are list of strings
  const existingOptions = (formValues?.options || []).map((item) =>
    item.toLowerCase(),
  );

  const resetFormValues = () => {
    if (disableInputs) {
      setDisableInputs(false);
    }
    setFormValues(initialFormValues);
  };

  const resetEditing = () => {
    setSelectedIndex(null);
    setLabelError(false);
    setNameError(false);
    resetFormValues();
  };

  // validate default value
  const defaultValueError = useMemo(() => {
    if (!formValues?.type) {
      return false;
    }
    const defaultValue = formValues?.defaultValue?.value
      ? formValues?.defaultValue?.value
      : formValues?.defaultValue;
    return formValues?.isHidden && formValues?.isMandatory && !defaultValue;
  }, [
    formValues?.type,
    formValues?.isHidden,
    formValues?.isMandatory,
    formValues?.defaultValue,
  ]);

  const isFormValid = () =>
    !!formValues?.name &&
    !!formValues?.label &&
    !!formValues?.type &&
    !defaultValueError &&
    !nameError &&
    !labelError &&
    !(formValues.type.value === 'list' && existingOptions.length === 0);

  const filteredCustomTypes = useMemo(
    () =>
      customTypes.filter((item) => {
        if (
          item.hasOwnProperty('allowMultiple') &&
          !item?.allowMultiple &&
          value.some((valueItem) => valueItem.type === item.value)
        ) {
          return false;
        }
        return true;
      }),
    [value, customTypes],
  );

  const getMetadataTypes = () => {
    const metadataTypes = [
      {
        label: 'String',
        value: 'string',
      },
      {
        label: 'List',
        value: 'list',
      },
      {
        label: 'Date',
        value: 'date',
      },
      ...filteredCustomTypes,
    ];
    return metadataTypes;
  };

  const listDefaultValueOptions = useMemo(() => {
    if (formValues?.type?.value === 'list') {
      return getOptionsFromString(formValues?.options);
    }
  }, [formValues]);

  const handleTextChange = (event) => {
    // validate label is unique
    if (event.target.dataset.label === 'label') {
      if (
        validateField({
          value: event.target.value,
          checkAgainst: existingLabels,
        })
      ) {
        setLabelError(true);
      } else {
        setLabelError(false);
      }
    }

    // validate name is unique
    if (event.target.dataset.label === 'name') {
      if (
        validateField({
          value: event.target.value,
          checkAgainst: existingNames,
        })
      ) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }

    setFormValues({
      ...formValues,
      [event.target.dataset.label]: event.target.value,
    });
  };

  const handleOptionItemTextChange = (nextValue) => {
    // validate option is unique
    if (
      validateField({
        value: nextValue,
        checkAgainst: existingOptions,
      })
    ) {
      setEditedOptionItemError(true);
    } else {
      setEditedOptionItemError(false);
    }

    setEditedOptionItem(nextValue);
  };

  const handleTypeChange = (selectedValue) => {
    // disable inputs if item values can't be changed
    if (selectedValue?.disableInputs) {
      setDisableInputs(true);
    } else if (disableInputs && !selectedValue.disableInputs) {
      setDisableInputs(false);
    }

    let updated = {
      ...formValues,
      name: selectedValue?.name ? selectedValue.name : '',
      label: selectedValue?.formLabel ? selectedValue.formLabel : '',
      type: {
        label: selectedValue?.label,
        value: selectedValue?.value,
      },
    };

    setFormValues(updated);
  };

  const handleDefaultOptionChange = (selectedValue) => {
    setFormValues({
      ...formValues,
      defaultValue: selectedValue,
    });
  };

  const handleCheckboxChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.dataset.label]: event.target.checked,
    });
  };

  const handleEdit = (item, index) => {
    if (isEditing && index === selectedIndex) {
      resetFormValues();
      resetEditing();
      return;
    }

    const typeData = customTypes.find(
      (customType) => customType.value === item.type,
    );

    if (typeData && typeData.disableInputs) {
      setDisableInputs(true);
    }

    const selectedType = getMetadataTypes().filter(
      (type) => type.value === item.type,
    );

    const defaultValue =
      item.type === 'list'
        ? {
            label: item?.defaultValue || '',
            value: item?.defaultValue || '',
          }
        : item?.defaultValue || '';

    setFormValues({
      ...item,
      type: selectedType[0],
      name: item.name || '',
      defaultValue: defaultValue,
      options: item.options || [],
    });
    setSelectedIndex(index);
  };

  const handleAdd = () => {
    let item = {
      ...formValues,
      name: formValues?.name?.trim(),
      label: formValues?.label?.trim(),
      defaultValue:
        formValues.type.value === 'list'
          ? formValues.defaultValue?.value
          : formValues.defaultValue?.trim(),
      type: formValues.type.value,
    };

    if (item.value === 'list') {
      item.options = formValues.options;
    }

    // clear default value if it is no longer in the options list
    if (
      item.value === 'list' &&
      !formValues.options.includes(item.defaultValue)
    ) {
      item.defaultValue = '';
    }

    let nextValue = [];

    if (isEditing) {
      nextValue = [...value];
      nextValue[selectedIndex] = item;
    } else {
      nextValue = Array.isArray(value) ? [...value, item] : [item];
    }

    onChange(nextValue);
    resetFormValues();

    if (isEditing) {
      resetEditing();
    }
  };

  const handleDelete = async (event) => {
    if (window.confirm('Are you sure you want to remove this variable?')) {
      const nextValue = value.filter(
        (item) => item.label !== event.currentTarget.dataset.label,
      );
      onChange(nextValue);
      if (isEditing) {
        resetEditing();
      }
    }
  };

  const handleSubmitOptionItem = () => {
    let updated = [...formValues.options];

    if (editedOptionItemIndex || editedOptionItemIndex === 0) {
      updated[editedOptionItemIndex] = editedOptionItem?.trim();
    } else {
      updated.push(editedOptionItem?.trim());
    }

    setFormValues({
      ...formValues,
      options: updated,
    });
    setEditedOptionItem('');
    setEditedOptionItemIndex(null);
  };

  const handleRemoveOptionItem = (index) => {
    const updated = [...formValues?.options];
    updated.splice(index, 1);

    setFormValues({
      ...formValues,
      options: updated,
    });
    setEditedOptionItem('');
    setEditedOptionItemIndex(null);
  };

  const handleCancelEdit = () => {
    setEditedOptionItem('');
    setEditedOptionItemIndex(null);
    setEditedOptionItemError(false);
  };

  const handleEditOptionItem = (option, index) => {
    if (editedOptionItemIndex === index) {
      setEditedOptionItem('');
      setEditedOptionItemIndex(null);
      setEditedOptionItemError(false);
      return;
    }

    setEditedOptionItem(option);
    setEditedOptionItemIndex(index);
  };

  const handleCloseOptionsEditDialog = () => {
    setEditedOptionItem('');
    setEditedOptionItemIndex(null);
    setEditOptionsList(false);
  };

  const renderValue = () =>
    value?.map((item, index) => (
      <tr
        key={item.name ? item.name : item.label}
        className={selectedIndex === index ? 'item-selected' : ''}
      >
        <td>{item.type}</td>
        <td>{item.name}</td>
        <td>{item.label}</td>
        <td className="text-center">{item.isMandatory ? 'Yes' : 'No'}</td>
        {enableReadOnly && (
          <td className="text-center">{item.readOnly ? 'Yes' : 'No'}</td>
        )}
        {enableSaml && (
          <td className="text-center">{item.isHidden ? 'Yes' : 'No'}</td>
        )}
        <td>
          <StyledWYSIWYGLabel
            noWrap
            dangerouslySetInnerHTML={{ __html: item.defaultValue }}
          />
        </td>
        <td style={{ minWidth: '179px' }}>{arrayToSting(item.options)}</td>
        <td className="text-center">
          <IconButton
            data-label={item.label}
            onClick={() => handleEdit(item, index)}
          >
            {selectedIndex === index ? (
              <CloseOutlinedIcon />
            ) : (
              <EditOutlinedIcon />
            )}
          </IconButton>
          <IconButton data-label={item.label} onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </td>
      </tr>
    ));

  return (
    <StyledFormContainer>
      <Table>
        <thead>
          <tr>
            <th className="text-left">Type</th>
            <th className="text-left">Name</th>
            <th className="text-left">Label</th>
            <th className="text-center">Required</th>
            {enableReadOnly && <th className="text-center">Read Only</th>}
            {enableSaml && <th className="text-center">SAML</th>}
            <th className="text-left">Default value</th>
            <th className="text-left">Options</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectControl
                data-label="type"
                onChange={handleTypeChange}
                options={getMetadataTypes()}
                value={formValues.type}
              />
            </td>
            <td>
              <TextControl
                data-label="name"
                onChange={handleTextChange}
                value={formValues.name}
                className={classNames({
                  'input-error': nameError,
                })}
                disabled={disableInputs}
              />
            </td>
            <td>
              <TextControl
                data-label="label"
                onChange={handleTextChange}
                value={formValues.label}
                className={classNames({
                  'input-error': labelError,
                })}
                disabled={disableInputs}
              />
            </td>
            <td className="text-center">
              <Checkbox
                data-label="isMandatory"
                onChange={handleCheckboxChange}
                value={formValues.isMandatory}
                checked={formValues.isMandatory}
              />
            </td>
            {enableReadOnly && (
              <td className="text-center">
                <Checkbox
                  data-label="readOnly"
                  onChange={handleCheckboxChange}
                  value={formValues.readOnly}
                  checked={formValues.readOnly}
                />
              </td>
            )}
            {enableSaml && (
              <td className="text-center">
                <Checkbox
                  data-label="isHidden"
                  onChange={handleCheckboxChange}
                  value={formValues.isHidden}
                  checked={formValues.isHidden}
                />
              </td>
            )}
            <td>
              {formValues?.type?.value === 'list' ? (
                <StyledSelectContainer
                  className={classNames({
                    'input-error': defaultValueError,
                  })}
                >
                  <SelectControl
                    data-label="defaultValue"
                    onChange={handleDefaultOptionChange}
                    options={listDefaultValueOptions}
                    getOptionLabel={(option) => (
                      <StyledWYSIWYGLabel
                        dangerouslySetInnerHTML={{ __html: option.label }}
                      />
                    )}
                    value={formValues.defaultValue}
                  />
                </StyledSelectContainer>
              ) : (
                <TextControl
                  data-label="defaultValue"
                  onChange={handleTextChange}
                  value={formValues.defaultValue}
                  className={classNames({
                    'input-error': defaultValueError,
                  })}
                />
              )}
            </td>
            <td>
              {formValues?.type?.value === 'list' && (
                <Button onClick={() => setEditOptionsList(true)} fullWidth>
                  {formValues.options.length ? 'Edit' : 'Add'}
                </Button>
              )}
            </td>
            <td className="text-center" style={{ minWidth: '100px' }}>
              <Button disabled={!isFormValid()} onClick={handleAdd} fullWidth>
                {isEditing ? 'Update' : 'Add'}
              </Button>
            </td>
          </tr>
          {renderValue()}
        </tbody>
      </Table>

      <Dialog
        title="Add List Options"
        onCloseDialog={handleCloseOptionsEditDialog}
        open={!!editOptionsList}
      >
        <StyledOptionsEditContainer>
          {formValues?.options?.length > 0 ? (
            <StyledOptionsList>
              {formValues?.options.map((option, optionIndex) => (
                <li key={option}>
                  <div className="option-item-label">
                    {editedOptionItemIndex === optionIndex ? (
                      <WysiwygForm
                        defaultValue={option}
                        onSubmit={handleSubmitOptionItem}
                        onCancel={handleCancelEdit}
                        disableSaveButton={
                          !editedOptionItem || editedOptionItemError
                        }
                        onChange={handleOptionItemTextChange}
                        disableRichText={disableRichText}
                      />
                    ) : (
                      <EditorControl readOnly value={option} />
                    )}
                  </div>

                  {editedOptionItemIndex !== optionIndex && (
                    <div>
                      <IconButton
                        disabled={showAddNewOptionForm}
                        onClick={() =>
                          handleEditOptionItem(option, optionIndex)
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        disabled={showAddNewOptionForm}
                        onClick={() => handleRemoveOptionItem(optionIndex)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  )}
                </li>
              ))}
            </StyledOptionsList>
          ) : (
            <p>No options added yet.</p>
          )}
          <div className="option-dialog-footer">
            {showAddNewOptionForm ? (
              <WysiwygForm
                defaultValue=""
                onSubmit={() => {
                  handleSubmitOptionItem();
                  setShowAddNewOptionForm(false);
                }}
                onCancel={() => {
                  setShowAddNewOptionForm(false);
                  handleCancelEdit();
                }}
                isNew
                disableSaveButton={
                  editedOptionItemIndex !== null ||
                  !editedOptionItem ||
                  editedOptionItemError
                }
                onChange={handleOptionItemTextChange}
                disableRichText={disableRichText}
              />
            ) : (
              <Button
                disabled={editedOptionItemIndex !== null}
                onClick={() => setShowAddNewOptionForm(true)}
              >
                Add new option
              </Button>
            )}
          </div>
        </StyledOptionsEditContainer>
      </Dialog>
    </StyledFormContainer>
  );
};

export default MetadataEditor;
