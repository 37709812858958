import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import pageImagesApi from '../Api/pageImages.api';
import { blobToDataUri } from '@clatter/platform';

export const PAGEIMAGES_FEATURE_KEY = 'pageImages';
export const pageImagesAdapter = createEntityAdapter({
  selectId: (row) => `${row.blockId}-${row.previewKey}`,
});

export const fetchPageImages = createAsyncThunk(
  `${PAGEIMAGES_FEATURE_KEY}/fetch`,
  async (documentId) => {
    const response = await pageImagesApi.fetchImagesList(documentId);

    const images = await Promise.all(
      response.data.map(async (image) => {
        const requestImageBlob = await pageImagesApi.fetchImage(
          documentId,
          image.previewKey,
        );

        const base64image = await blobToDataUri(requestImageBlob, {
          type: 'image/jpeg',
        });

        return {
          ...image,
          image: base64image,
        };
      }),
    );

    return Promise.resolve(images);
  },
);

export const initialPageImagesState = pageImagesAdapter.getInitialState({
  loadingStatus: 'not loaded',
  error: null,
});

export const pageImagesSlice = createSlice({
  name: PAGEIMAGES_FEATURE_KEY,
  initialState: initialPageImagesState,
  reducers: {
    add: pageImagesAdapter.addOne,
    remove: pageImagesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageImages.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchPageImages.fulfilled, (state, action) => {
        pageImagesAdapter.setAll(state, action.payload);
        state.loadingStatus = 'loaded';
      })
      .addCase(fetchPageImages.rejected, (state, action) => {
        state.loadingStatus = 'error';
        state.error = action.error.message;
      });
  },
});

export const pageImagesReducer = pageImagesSlice.reducer;

export const pageImagesActions = pageImagesSlice.actions;

const { selectAll, selectEntities } = pageImagesAdapter.getSelectors();

export const getPageImagesState = (rootState) =>
  rootState[PAGEIMAGES_FEATURE_KEY];

export const selectAllPageImages = createSelector(
  getPageImagesState,
  selectAll,
);

export const selectPageImagesEntities = createSelector(
  getPageImagesState,
  selectEntities,
);
