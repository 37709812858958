import { generatePath } from 'react-router-dom';
import {
  VerticalSplit as VerticalSplitIcon,
  Summarize as SummarizeIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import {
  featureFlagsMap,
  generateReportsUrls,
  hasActiveFeatureFlag,
  hasRole,
  TOOL_SM_SLUG,
  TOOL_QC_SLUG,
  TOOL_OE_SLUG,
  TOOL_OEKIT_SLUG,
  TOOL_CM_SLUG,
} from '@clatter/platform';
import routes from '../routes';
import { userRolesMap } from '.';

export const getMenuItems = ({ user, reports, featureFlags, activeTool }) => {
  const menuItems = [];
  if (!user) {
    return menuItems;
  }

  menuItems.push({
    label: 'Project Library',
    url: generatePath(routes.documents),
    icon: VerticalSplitIcon,
    exact: true,
  });

  if (
    hasRole(userRolesMap.summaryMakerAdmin, user) ||
    hasRole(userRolesMap.qcAdmin, user) ||
    hasRole(userRolesMap.oeAdmin, user) ||
    hasRole(userRolesMap.oeKitAdmin, user) ||
    hasRole(userRolesMap.cmAdmin, user)
  ) {
    menuItems.push({
      label: 'Templates',
      url: generatePath(routes.templates),
      icon: VerticalSplitIcon,
      exact: true,
    });

    if (
      activeTool &&
      featureFlags &&
      Array.isArray(featureFlags) &&
      hasActiveFeatureFlag({
        flagName: (() => {
          switch (activeTool.slug) {
            case TOOL_SM_SLUG:
              return featureFlagsMap.SM.reports;
            case TOOL_QC_SLUG:
              return featureFlagsMap.QC.reports;
            case TOOL_OE_SLUG:
              return featureFlagsMap.OE.reports;
            case TOOL_OEKIT_SLUG:
              return featureFlagsMap.OEKIT.reports;
            case TOOL_CM_SLUG:
              return featureFlagsMap.CM.reports;
          }
          return false;
        })(),
        featureFlags: featureFlags,
      })
    ) {
      menuItems.push({
        label: 'Reports',
        children: generateReportsUrls({
          reports: reports,
          featureFlags: featureFlags,
        }),
        icon: SummarizeIcon,
      });
    }
  }

  if (
    activeTool &&
    activeTool.help_url &&
    ((activeTool.slug === TOOL_SM_SLUG &&
      hasActiveFeatureFlag({
        flagName: featureFlagsMap.SM.help,
        featureFlags: featureFlags,
      })) ||
      (activeTool.slug === TOOL_QC_SLUG &&
        hasActiveFeatureFlag({
          flagName: featureFlagsMap.QC.help,
          featureFlags: featureFlags,
        })) ||
      (activeTool.slug === TOOL_OE_SLUG &&
        hasActiveFeatureFlag({
          flagName: featureFlagsMap.OE.help,
          featureFlags: featureFlags,
        })) ||
      (activeTool.slug === TOOL_OEKIT_SLUG &&
        hasActiveFeatureFlag({
          flagName: featureFlagsMap.OEKIT.help,
          featureFlags: featureFlags,
        })) ||
      (activeTool.slug === TOOL_CM_SLUG &&
        hasActiveFeatureFlag({
          flagName: featureFlagsMap.CM.help,
          featureFlags: featureFlags,
        })))
  ) {
    menuItems.push({
      label: 'Support',
      url: activeTool.help_url,
      icon: HelpIcon,
      external: true,
    });
  }

  return menuItems;
};
