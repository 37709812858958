import React, { useState, useCallback } from 'react';
import {
  DndContext,
  rectIntersection,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { get } from 'lodash';
import { SortableContext } from '@dnd-kit/sortable';
import { Grid } from '@mui/material';
import SortableImageItem from './Components/SortableImageItem';
import { difference, isFunction } from 'lodash';
import styled from 'styled-components';

const StyledThumbnailView = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`;

const StyledNoImages = styled.div`
  min-height: 201px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey};
`;

const SortableImageGallery = ({
  images = [],
  imageUrlProperty = 'thumbnailLocation.url',
  titleProperty = 'title',
  handleImageDelete,
  handleImageDownload,
  checkItemDownloadable,
  coverSlideId,
  onAdd,
  onChange,
  onSelect,
  contentSlidesGroups,
  selectedContentElementId = null,
  disableItemEdit = false,
  errors = null
}) => {
  const [activeItems, setActiveItems] = useState([]);
  const [overId, setOverId] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const imagesIds = images?.map((image) => image._id);
  const onClearActiveItems = () => setActiveItems([]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // activation constraint is needed to enable click events from slide item
      },
    }),
  );

  const resetSlideDragState = () => {
    setOverId(null);
    setActiveId(null);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.data.current.disabled) {
      return;
    }

    if (overId) {
      resetSlideDragState();
    }

    let isDraggingMultipleSlides = false;
    let slidesToMoveHashedIds = [];

    // handle move group
    if (active?.data?.current?.groupId) {
      isDraggingMultipleSlides = true;
      for (const slideId of contentSlidesGroups[
        active?.data?.current?.groupId
      ]) {
        slidesToMoveHashedIds.push(
          imagesIds.find((hashed) => hashed.includes(slideId)),
        );
      }
    }

    // handle move multiple selected
    if (activeItems.some((id) => id === active.id)) {
      isDraggingMultipleSlides = true;
      slidesToMoveHashedIds = [...activeItems];
    }

    // adding item to the presentation
    if (
      active.id !== overId &&
      !over.data.current.droppable &&
      active.data.current.droppable
    ) {
      // insert after hovered element
      const insertAfterIndex = imagesIds.indexOf(overId) + 1;
      onAdd({ index: insertAfterIndex, slideId: active.id });
      return;
    }

    // sorting items
    if (active.id !== overId) {
      const slidesWithoutActiveItems = difference(
        imagesIds,
        isDraggingMultipleSlides ? slidesToMoveHashedIds : [active.id],
      );
      const newIndex =
        slidesWithoutActiveItems.indexOf(overId) ??
        slidesWithoutActiveItems.length;

      const oldIndex = active?.data?.current?.sortable.index;

      onChange({
        insertAt: newIndex,
        oldIndex: oldIndex,
        isDraggingMultipleSlides: isDraggingMultipleSlides,
        slidesToMoveHashedIds: slidesToMoveHashedIds,
      });
    }

    // clear multi selection
    onClearActiveItems();
  };

  const handleDragStart = (event) => {
    setActiveId(event.active?.id);
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (active?.id === coverSlideId) {
      return;
    }

    // check if hovering over group item and set overId accordingly
    if (active?.id !== over?.id) {
      setOverId(over?.id);
    }
  };

  const isItemActive = useCallback(
    (itemId) => activeItems.some((id) => id === itemId),
    [activeItems.length],
  );

  const isItemDownloadable = (item) => checkItemDownloadable?.(item) ?? false;

  // handle selecting multiple slides
  const handleSlideSelect = ({ event, item }) => {
    // select only one item
    if (onSelect && isFunction(onSelect)) {
      onSelect(item);
    }

    // select multiple items
    if (event.shiftKey) {
      let updated = [...activeItems];

      if (isItemActive(item._id)) {
        updated = activeItems.filter(
          (activeItemId) => activeItemId !== item._id,
        );
      } else {
        updated = [...updated, item._id];
      }

      setActiveItems(updated);
    }
  };

  if (!images?.length) {
    return (
      <StyledNoImages>
        <p>No images</p>
      </StyledNoImages>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
    >
      <Grid container spacing={2} alignItems="stretch">
        <SortableContext items={imagesIds}>
          <StyledThumbnailView>
            {images.map((image, index) => (
              <SortableImageItem
                id={image?._id}
                key={image?._id}
                index={image?._id}
                image={image}
                activeId={activeId}
                isActive={isItemActive(image._id)}
                isDownloadable={isItemDownloadable(image)}
                overId={overId}
                onRemove={handleImageDelete}
                onDownload={handleImageDownload}
                onSelect={handleSlideSelect}
                url={get(image, imageUrlProperty)}
                alt={get(image, titleProperty)}
                selectedContentElementId={selectedContentElementId}
                disableItemEdit={disableItemEdit}
                errors={errors}
              />
            ))}
          </StyledThumbnailView>
        </SortableContext>
      </Grid>
    </DndContext>
  );
};

export default SortableImageGallery;
