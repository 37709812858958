export const documentTypesMap = {
  PDF: {
    value: 'application/pdf',
    name: 'PDF',
  },
  PPTX: {
    value:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'PPTX',
  },
};

export const documentTypesOptions = [
  {
    value: documentTypesMap.PDF.value,
    label: documentTypesMap.PDF.name,
  },
  {
    value: documentTypesMap.PPTX.value,
    label: documentTypesMap.PPTX.name,
  },
];
