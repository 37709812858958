import { getAxiosWithAuthorization } from '@clatter/platform';
import { awsService } from '@clatter/ui';

const documentsApi = {
  fetchDocumentFile: ({ userId, documentId, contentType = 'application/pdf' }) => getAxiosWithAuthorization({
    url: `/summary/users/${userId}/documents/${documentId}/file`,
    headers: {
      'Content-Type': contentType,
    },
    data: {}, // hack fo axios => https://github.com/axios/axios/issues/86#issuecomment-139638284
    method: 'GET',
    responseType: 'blob',
  }),
  fetchDocumentStatus: ({ documentId }) =>
    getAxiosWithAuthorization({
      url: `/summary/documents/${documentId}`,
      method: 'GET',
    }),
  fetchUserDocument: ({ userId, documentId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}`,
      method: 'GET',
    }),
  fetchUserDocuments: ({ userId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents`,
      method: 'GET',
    }),
  deleteUserDocument: ({ userId, documentId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}`,
      method: 'DELETE',
    }),
  createUserDocument: ({ userId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents`,
      method: 'POST',
      data: data,
    }),
  cloneUserDocument: ({ userId, documentId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}`,
      method: 'POST',
    }),
  updateUserDocument: ({ userId, documentId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}`,
      method: 'PATCH',
      data: data,
    }),
  updateUserDocumentBlockContent: ({ userId, documentId, blockId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}`,
      method: 'PATCH',
      data: data,
    }),
  updateDocumentBlockVariables: ({ documentId, blockId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/documents/${documentId}/blocks/${blockId}/variables`,
      method: 'PATCH',
      data: data,
    }),
  generateDocumentBlockFilePresignedUrl: (
    { userId, documentId, blockId, data },
    onUploadProgress,
  ) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/files`,
      method: 'POST',
      data: data,
      onUploadProgress: onUploadProgress,
    }),
  uploadDocumentToS3: (
    { userId, documentId, blockId, file, overrideFileType },
    onUploadProgress,
  ) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/files`,
      method: 'POST',
      data: {
        name: file.name,
        fileName: file.name,
        fileType: overrideFileType || file.type,
      },
    }).then(
      async ({ data }) =>
        await awsService.putFile(data.presignedUrl, file, onUploadProgress),
    ),
  fetchBlockFiles: ({ userId, documentId, blockId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/files`,
      method: 'GET',
    }),
  deleteBlockItem: ({ userId, documentId, blockId, itemId }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/${itemId}`,
      method: 'DELETE',
    }),
  updateBlockItem: ({ userId, documentId, blockId, itemId, data }) =>
    getAxiosWithAuthorization({
      url: `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/items/${itemId}`,
      method: 'PATCH',
      data: data,
    }),
};

export default documentsApi;
