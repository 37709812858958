import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Tooltip from '../../Tooltip/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import styled from 'styled-components';

const StyledGalleryItem = styled.div`
  max-width: 160px;
  position: relative;

  img {
    width: 160px;
    height: 160px;
    object-fit: contain;
  }

  svg {
    cursor: pointer;
    font-size: 16px;
    fill: #666;
    margin-left: 8px;
  }

  .gallery-item-title {
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gallery-item-details {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: white !important;

    input {
      max-height: 30px;
      max-width: 100%;
      flex: auto;
    }
  }

  .gallery-item-actions {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
  }

  .gallery-item-footer {
    display: flex;
    align-items: center;
  }

  .color-green {
    fill: green;
  }

`;

const StyledDeleteButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: -5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
  }

  svg {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0;
  }
`;

const ImageGalleryItem = ({ image, url, alt, onDelete }) => {
  const formRef = useRef(document.getElementById('blockForm'));
  const wrapperRef = useRef();
  const previousTitle = useRef(); // keep original item title, so we can revert after cancel edit
  const [editedItemId, setEditedItemId] = useState(null);

  const { register, setValue, getValues, setFocus } = useFormContext();

  const isEditing = editedItemId === image?._id;

  const itemIndexInFormData = useMemo(() => {
    return (getValues('contentElements') || []).findIndex(
      (item) => item?._id === image?._id,
    );
  }, [image?._id, getValues('contentElements')]);

  const handleToggleEdit = () => {
    if (!getValues(`contentElements.${itemIndexInFormData}.title`)) {
      setValue(`contentElements.${itemIndexInFormData}.title`, image?.name);
    }
    setEditedItemId(image?._id);
  };
  const handleConfirmEdit = () => {
    formRef.current.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      }),
    );
    setEditedItemId(null);
  };
  const handleCancelEdit = () => {
    setValue(
      `contentElements.${itemIndexInFormData}.title`,
      previousTitle?.current || '',
    );
    setEditedItemId(null);
  };

  const handleRemoveImage = () => {
    if (confirm('Remove Image?')) {
      onDelete(image?._id)
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      handleCancelEdit();
    }
  };

  useEffect(() => {
    if (getValues(`contentElements.${itemIndexInFormData}.title`)) {
      previousTitle.current = getValues(
        `contentElements.${itemIndexInFormData}.title`,
      );
    }
  }, [getValues(`contentElements.${itemIndexInFormData}.title`)]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      setFocus(`contentElements.${itemIndexInFormData}.title`);
    }
  }, [isEditing, itemIndexInFormData]);

  return (
    <StyledGalleryItem key={image?._id} ref={wrapperRef}>
      <StyledDeleteButton
        className="gallery-image-delete-button"
        onClick={handleRemoveImage}
      >
        <DeleteOutlineIcon />
      </StyledDeleteButton>
      <img src={url} alt={alt} />
      <div className="gallery-item-details">
        {isEditing ? (
          <div className="gallery-item-edit-container">
            <input
              type="text"
              minLength={1}
              {...register(`contentElements.${itemIndexInFormData}.title`)}
            />
            <div className="gallery-item-actions">
              <CheckIcon onClick={handleConfirmEdit} className="color-green" />
              <ClearIcon onClick={handleCancelEdit} />
            </div>
          </div>
        ) : (
          <div className="gallery-item-footer">
            <Tooltip
              text={
                getValues(`contentElements.${itemIndexInFormData}.title`) ||
                image?.name
              }
            >
              <span className="gallery-item-title" onClick={handleToggleEdit}>
                {getValues(`contentElements.${itemIndexInFormData}.title`) ||
                  image?.name}
              </span>
            </Tooltip>

            <div className="gallery-item-actions">
              <EditIcon
                className="gallery-item-edit-button"
                onClick={handleToggleEdit}
              />
            </div>
          </div>
        )}
      </div>
    </StyledGalleryItem>
  );
};

export default ImageGalleryItem;
