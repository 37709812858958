import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { requestStatus } from '@clatter/platform';
import documentsApi from '../Api/documents.api';

export const DOCUMENTS_FEATURE_KEY = 'documents';
export const documentsAdapter = createEntityAdapter({
  selectId: (row) => row._id,
  sortComparer: (a, b) => (a.updatedAt > b.updatedAt ? -1 : 1),
});

export const fetchAllDocuments = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/fetchAll`,
  async ({ userId }) => await documentsApi.fetchUserDocuments({ userId }),
);

export const deleteDocument = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/delete`,
  async ({ userId, documentId }, { rejectWithValue }) => {
    try {
      return await documentsApi.deleteUserDocument({
        userId,
        documentId,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const createDocument = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/create`,
  async ({ userId, data }) =>
    await documentsApi.createUserDocument({ userId, data }),
);

export const fetchSingleDocument = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/fetchSingle`,
  async ({ userId, documentId }) =>
    await documentsApi.fetchUserDocument({ userId, documentId }),
);

export const updateDocument = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/update`,
  async ({ userId, documentId, data }) =>
    await documentsApi.updateUserDocument({ userId, documentId, data }),
);

export const cloneDocument = createAsyncThunk(
  `${DOCUMENTS_FEATURE_KEY}/clone`,
  async ({ userId, documentId }) =>
    await documentsApi.cloneUserDocument({ userId, documentId }),
);

export const initialDocumentsState = documentsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
});
export const documentsSlice = createSlice({
  name: DOCUMENTS_FEATURE_KEY,
  initialState: initialDocumentsState,
  reducers: {
    add: documentsAdapter.addOne,
    remove: documentsAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      // fetch all documents
      .addCase(fetchAllDocuments.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchAllDocuments.fulfilled, (state, { payload }) => {
        documentsAdapter.setAll(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchAllDocuments.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // fetch single document
      .addCase(fetchSingleDocument.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchSingleDocument.fulfilled, (state, { payload }) => {
        documentsAdapter.setOne(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchSingleDocument.rejected, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // delete document
      .addCase(deleteDocument.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deleteDocument.fulfilled, (state, { meta }) => {
        documentsAdapter.removeOne(state, meta.arg.documentId);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deleteDocument.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // create document
      .addCase(createDocument.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(createDocument.fulfilled, (state, { payload }) => {
        documentsAdapter.addOne(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createDocument.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // update document
      .addCase(updateDocument.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateDocument.fulfilled, (state, { payload }) => {
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateDocument.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // clone document
      .addCase(cloneDocument.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(cloneDocument.fulfilled, (state, { payload }) => {
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(cloneDocument.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});
/*
 * Export reducer for store configuration.
 */
export const documentsReducer = documentsSlice.reducer;
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(documentsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const documentsActions = documentsSlice.actions;
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllDocuments);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities, selectById } =
  documentsAdapter.getSelectors();
export const getDocumentsState = (rootState) =>
  rootState[DOCUMENTS_FEATURE_KEY];

export const selectAllDocuments = createSelector(getDocumentsState, selectAll);
export const selectDocumentsByTemplateIds = (templateIds = []) =>
  createSelector(getDocumentsState, ({ ids, entities }) => {
    return Object.values(entities).reduce((list, document) => {
      if ((templateIds || []).includes(document?.template._id)) {
        list.push(document);
      }
      return list;
    }, []);
  });

export const selectDocumentById = (documentId) =>
  createSelector(getDocumentsState, (state) => selectById(state, documentId));

export const selectDocumentsEntities = createSelector(
  getDocumentsState,
  selectEntities,
);
