import getAxiosApiV2WithAuthorization from '../services/axiosApiV2Factory';

const uploadsApi = {
  updateUpload: ({ id, uploadStatus }) =>
    getAxiosApiV2WithAuthorization({
      url: `/uploads/${id}`,
      method: 'patch',
      data: {
        upload_status: uploadStatus,
      },
    }),
  getUpload: ({ id }) =>
    getAxiosApiV2WithAuthorization({
      url: `/uploads/${id}`,
      method: 'get',
    }),
  createUpload: ({ toolId, fileName, metadata }) =>
    getAxiosApiV2WithAuthorization({
      url: `/uploads`,
      method: 'post',
      data: {
        tool_id: toolId,
        file_name: fileName,
        metadata,
      },
    }),
};

export default uploadsApi;
