import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loader, appGlobalStyles, AppThemeProvider, SsoCallbackPage, TrackPageviewEvent } from '@clatter/ui';
import {
  Analytics,
  fetchToolsConfig,
  fetchToolInfo,
  useAuth,
  fetchFeatureFlag,
  fetchSystemProfile,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import { ProtectedRoute, LogoutPageView, sharedRoutes } from '@clatter/platform';
import routes from './routes';
import { fetchAllTemplates } from './Store/templates.slice';
import { userRolesMap } from './helpers';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const LandingPageView = lazy(() =>
  import('./Pages/LandingPage/LandingPageView'),
);
const DocumentsListView = lazy(() =>
  import('./Pages/Documents/DocumentsListView'),
);
const DocumentsAddView = lazy(() =>
  import('./Pages/Documents/DocumentsAdd/DocumentAddView'),
);
const DocumentsEditView = lazy(() =>
  import('./Pages/Documents/DocumentEdit/DocumentEditView'),
);
const TemplatesListView = lazy(() =>
  import('./Pages/Templates/TemplatesListView'),
);
const TemplatesAddView = lazy(() =>
  import('./Pages/Templates/TemplateAdd/TemplateAddView'),
);
const TemplateBuildView = lazy(() =>
  import('./Pages/Templates/TemplateBuild/TemplateBuildView'),
);
const DocumentsByUserReportView = lazy(() =>
  import('./Pages/Reports/DocumentsByUser/DocumentsByUserReportView'),
);
const DocumentsDownloadsReportView = lazy(() =>
  import('./Pages/Reports/DocumentsDownloads/DocumentsDownloadsReportView'),
);
const DocumentsCreateReportView = lazy(() =>
  import('./Pages/Reports/DocumentsCreate/DocumentsCreateReportView'),
);
const TemplateUsageReportView = lazy(() =>
  import('./Pages/Reports/TemplateUsage/TemplateUsageReportView'),
);

const App = () => {
  const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;
  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchAllTemplates());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <Router>
        <TrackPageviewEvent />
        <Analytics />

        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={routes.index} component={LandingPageView} />
            <Route exact path={routes.logout} component={LogoutPageView} />
            <Route exact path={sharedRoutes.ssoCallback} component={SsoCallbackPage} />
            <ProtectedRoute
              exact
              path={routes.documents}
              component={DocumentsListView}
              accessRoles={[
                userRolesMap.summaryMaker,
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcUser,
                userRolesMap.qcAdmin,
                userRolesMap.oeUser,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitUser,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmUser,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.addDocument}
              component={DocumentsAddView}
              accessRoles={[
                userRolesMap.summaryMaker,
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcUser,
                userRolesMap.qcAdmin,
                userRolesMap.oeUser,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitUser,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmUser,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.editDocument}
              component={DocumentsEditView}
              accessRoles={[
                userRolesMap.summaryMaker,
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcUser,
                userRolesMap.qcAdmin,
                userRolesMap.oeUser,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitUser,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmUser,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.templates}
              component={TemplatesListView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              path={routes.addTemplates}
              component={TemplatesAddView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              path={routes.buildTemplate}
              component={TemplateBuildView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              path={routes.reportTemplatesUsage}
              component={TemplateUsageReportView}
              exact
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.reportDocumentsByUser}
              component={DocumentsByUserReportView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.reportDocumentsDownloads}
              component={DocumentsDownloadsReportView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
            <ProtectedRoute
              exact
              path={routes.reportDocumentsCreate}
              component={DocumentsCreateReportView}
              accessRoles={[
                userRolesMap.summaryMakerAdmin,
                userRolesMap.qcAdmin,
                userRolesMap.oeAdmin,
                userRolesMap.oeKitAdmin,
                userRolesMap.cmAdmin,
              ]}
            />
          </Switch>
        </Suspense>
      </Router>
    </AppThemeProvider>
  );
};
export default App;
