import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Tooltip from '../../Tooltip/Tooltip';
import { useSortable } from '@dnd-kit/sortable';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import placeholderImage from './placeholderImage.png';
import FloatingButton from '../../FloatingButton/FloatingButton';
import { Stack } from "@mui/material";
import { topicErrorMessagesMap, useDeepCompareMemo } from "@clatter/platform";

const StyledGalleryItem = styled.div`
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  min-height: 128px;

  margin: 10px;
  &.dragging {
    z-index: 5;
    opacity: ${({ theme }) => theme.slideDividerThumbnail.draggingOpacity};
  }

  &:focus {
    outline: none !important;
  }

  &.active {
    &:after {
      z-index: 2;
      pointer-events: none;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: none;
      border: ${({ theme }) => theme.slideSortThumbnail.activeBorder};
    }
  }

  &.reusable {
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 22px;
      left: 10px;
      border-radius: 50%;
      background-color: orange;
    }
  }

  &.content-over {
    &:after {
      position: absolute;
      content: '';
      width: ${({ theme }) => theme.slideSortThumbnail.slideOverBarWidth};
      height: 100%;
      background-color: ${({ theme }) =>
        theme.slideSortThumbnail.slideOverBarColor};
      right: -10px;
      top: 0;
    }
  }

  &.selected {
    border: 3px solid ${({ theme }) => theme.palette.success};
  }

  &.has-error {
    border: 3px solid ${({ theme }) => theme.palette.error};
  }

  &:hover {
    > img {
      transform: scale(0.93);
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.3s ease-in-out;
    box-shadow: ${({ theme }) => theme.slideSortThumbnail.hoverShadow};
  }

  &.highlighted {
    background-color: ${({ theme }) =>
      theme.slideSortThumbnail.addedBackgroundColor};

    img {
      opacity: 0.6;
    }
  }

  img {
    padding: 0 10px;
    width: 160px;
    height: 160px;
    object-fit: contain;
  }

  svg {
    cursor: pointer;
    font-size: 16px;
    fill: #666;
    margin-left: 8px;
  }

  .gallery-item-title {
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gallery-item-details {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: white !important;
    padding: 0px 8px 5px;

    input {
      max-height: 30px;
      max-width: 100%;
      flex: auto;
    }
  }

  .gallery-item-actions {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
  }

  .gallery-item-footer {
    display: flex;
    align-items: center;
  }

  .color-green {
    fill: green;
  }
`;

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error};
  padding: 0 10px;
`

const SortableImageItem = ({
  id,
  isActive,
  isDownloadable = false,
  activeId,
  overId,
  onRemove,
  onSelect,
  onDownload,
  image,
  url,
  alt,
  errors,
  selectedContentElementId,
  disableItemEdit = false,
}) => {
  const formRef = useRef(document.getElementById('blockForm'));
  const previousTitle = useRef(); // keep original item title, so we can revert after cancel edit
  const [editedItemId, setEditedItemId] = useState(null);

  const { isDragging, attributes, listeners, setNodeRef, transform, node } =
    useSortable({
      id: id,
    });

  const { register, setValue, getValues, setFocus } = useFormContext();

  const isEditing = editedItemId === image?._id;

  const imageItemErrors = useDeepCompareMemo(() => {
    let imageErrors = []

    if (errors && Object.keys(errors)?.length) {
      Object.keys(errors).forEach(errorType => {
        if (errors[errorType]?.includes(id)) {
          imageErrors.push(errorType);
        }
      })
    }
    return imageErrors.length ? imageErrors : null;
  }, [errors])

  const itemIndexInFormData = useMemo(() => {
    return getValues('contentElements')?.findIndex(
      (item) => item?._id === image?._id,
    );
  }, [image?._id, getValues('contentElements')]);

  const getTransform = () => {
    if (isDragging) {
      return CSS.Transform.toString(transform);
    }
  };

  const style = {
    transform: getTransform(),
    transition: 'width .3s ease',
  };

  const handleToggleEdit = () => {
    if (!disableItemEdit) {
      if (!getValues(`contentElements.${itemIndexInFormData}.title`)) {
        setValue(`contentElements.${itemIndexInFormData}.title`, image?.name);
      }
      setEditedItemId(image?._id);
    }
  };
  const handleConfirmEdit = () => {
    formRef.current.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      }),
    );
    setEditedItemId(null);
  };
  const handleCancelEdit = () => {
    setValue(
      `contentElements.${itemIndexInFormData}.title`,
      previousTitle?.current || '',
    );
    setEditedItemId(null);
  };

  const handleRemoveImage = () => {
    if (confirm('Remove Image?')) {
      onRemove(image?._id);
    }
  };

  const handleDownloadImage = () => {
    if (!isDownloadable) return;

    onDownload?.(image);
  };

  const handleClickOutside = (event) => {
    if (!disableItemEdit && node && !node.current.contains(event.target)) {
      handleCancelEdit();
    }
  };

  useEffect(() => {
    if (
      !disableItemEdit &&
      getValues(`contentElements.${itemIndexInFormData}.title`)
    ) {
      previousTitle.current = getValues(
        `contentElements.${itemIndexInFormData}.title`,
      );
    }
  }, [getValues(`contentElements.${itemIndexInFormData}.title`)]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      setFocus(`contentElements.${itemIndexInFormData}.title`);
    }
  }, [isEditing, itemIndexInFormData]);

  const itemOver = overId === image._id && overId !== activeId;

  const handleSelect = (event) => onSelect({ event, item: image });

  return (
    <Stack direction="column">
    <StyledGalleryItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={image?._id}
      className={classNames({
        active: isActive,
        dragging: isDragging,
        'content-over': itemOver && !isDragging,
        reusable: image?.reusable,
        'has-error': imageItemErrors?.length,
        selected: selectedContentElementId === image?._id,
      })}
    >
      <div className="item-wrapper">
        <FloatingButton
          className="gallery-image-download-button"
          onClick={handleDownloadImage}
          isHidden={!isDownloadable}
          top={13}
          right={40}
        >
          <CloudDownloadOutlinedIcon />
        </FloatingButton>
        <FloatingButton
          className="gallery-image-delete-button"
          onClick={handleRemoveImage}
          top={13}
          right={5}
        >
          <DeleteOutlineIcon />
        </FloatingButton>
        <img src={url || placeholderImage} alt={alt} onClick={handleSelect} />
        <div className="gallery-item-details">
          {isEditing ? (
            <div className="gallery-item-edit-container">
              <input
                type="text"
                minLength={1}
                {...register(`contentElements.${itemIndexInFormData}.title`)}
              />
              <div className="gallery-item-actions">
                <CheckIcon
                  onClick={handleConfirmEdit}
                  className="color-green"
                />
                <ClearIcon onClick={handleCancelEdit} />
              </div>
            </div>
          ) : (
            <div className="gallery-item-footer">
              <Tooltip
                text={
                  getValues(`contentElements.${itemIndexInFormData}.title`) ||
                  image?.name
                }
              >
                <span className="gallery-item-title" onClick={handleToggleEdit}>
                  {getValues(`contentElements.${itemIndexInFormData}.title`) ||
                    image?.name}
                </span>
              </Tooltip>

              {!disableItemEdit && (
                <div className="gallery-item-actions">
                  <EditIcon
                    className="gallery-item-edit-button"
                    onClick={handleToggleEdit}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledGalleryItem>
      {
        imageItemErrors?.length &&
        <StyledErrorMessage>{topicErrorMessagesMap[imageItemErrors[0]]}</StyledErrorMessage>
      }
    </Stack>
  );
};

export default SortableImageItem;
