import {
  downloadBlobFile,
  mimeTypes,
  mimeTypesToFileExtensionMap,
} from '@clatter/platform';
import documentsApi from '../Api/documents.api';

export const downloadDocument = async ({
  userId,
  fileName,
  documentId,
  contentType = mimeTypes.PDF,
}) => {
  const image = await documentsApi.fetchDocumentFile({
    userId: userId,
    documentId: documentId,
    contentType: contentType,
  });

  return downloadBlobFile(
    image,
    `${fileName}.${mimeTypesToFileExtensionMap[contentType]}`,
  );
};
