export * from './blocks';
export * from './menuItems';
export * from './consts';
export * from './documents';
export * from './files';


export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });
};

export const triggerFormSubmit = (blockId = 'blockForm') => {
  document.getElementById(blockId)?.dispatchEvent(
    new Event('submit', {
      bubbles: true,
      cancelable: true,
    }),
  );
};

export const userRolesMap = {
  summaryMaker: 'Summary Maker',
  summaryMakerAdmin: 'Summary Maker Admin',
  qcUser: 'QC-User',
  qcAdmin: 'QC-Admin',
  oeUser: 'OE-User',
  oeAdmin: 'OE-Admin',
  oeKitUser: 'OEKIT-User',
  oeKitAdmin: 'OEKIT-Admin',
  cmUser: 'CM-User',
  cmAdmin: 'CM-Admin',
};

export const documentBuildStatuses = {
  current: 'current',
  unknown: 'unknown',
  pending: 'pending',
  failed: 'failed',
};
