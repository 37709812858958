import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import templatesApi from '../Api/templates.api';
import { requestStatus } from '@clatter/platform';

export const TEMPLATES_FEATURE_KEY = 'templates';
export const templatesAdapter = createEntityAdapter({
  selectId: (row) => row._id,
});

// this would be removed when api will return proper blcoks data
const addDocumentVariablesBlock = (data) => {
  const documentBlockForm = {
    builtin: false,
    contentElements: [],
    description: 'Document level variables',
    display_name: 'Document',
    name: 'documentVariables',
    thumbnailLocation:
      'https://clatter-coley-dev-main.s3.amazonaws.com/sm/block-definitions/thumbnails/layout.png',
    type: 'documentVariables',
    _id: 'templatevariablesblockid12761277',
  };

  return {
    ...data,
    blocks: data?.blocks
      ? [...data.blocks, documentBlockForm]
      : [documentBlockForm],
  };
};

export const fetchAllTemplates = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/fetch`,
  async (params) => await templatesApi.fetchTemplates(params),
);

export const fetchSingleTemplate = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/fetchSingle`,
  async ({ templateId }, { rejectWithValue }) => {
    try {
      return await templatesApi.fetchTemplate({ templateId: templateId });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateTemplate = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/update`,
  async ({ templateId, data }, { rejectWithValue }) => {
    try {
      await templatesApi.updateTemplate({
        templateId,
        data,
      });

      return await templatesApi.fetchTemplate({ templateId });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteTemplate = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/delete`,
  async ({ templateId }, { rejectWithValue }) => {
    try {
      return await templatesApi.deleteTemplate({ templateId: templateId });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const cloneTemplate = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/clone`,
  async ({ templateId }, { rejectWithValue }) => {
    try {
      return await templatesApi.cloneTemplate({ templateId: templateId });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const fetchBlocks = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/fetchBlocks`,
  async (params, { rejectWithValue }) => {
    try {
      const customBlocks = await templatesApi.fetchCustomBlocks();
      const builtInBlocks = await templatesApi.fetchBuiltInBlocks();

      return await Promise.resolve({
        customBlocks: customBlocks.data,
        builtInBlocks: builtInBlocks.data,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateBlock = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/updateBlock`,
  async ({ blockId, data, templateId, disableFetch = false }, { rejectWithValue }) => {
    try {
      await templatesApi.updateBlock({ blockId, data, templateId });
      // when updating template block details such as "contentElements", uploaded files etc.
      // refetching block definitions is unnecessary.
      if (disableFetch) {
        return { success: true, data: null };
      }
      // This fetch is required only when updating block definition data directly (such as block name)
      return await templatesApi.fetchCustomBlocks();
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const createBlock = createAsyncThunk(
  `${TEMPLATES_FEATURE_KEY}/createBlock`,
  async ({ data, templateId }, { rejectWithValue }) => {
    try {
      await templatesApi.createBlock({ data, templateId });
      return await templatesApi.fetchCustomBlocks();
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const initialTemplatesState = templatesAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: null,
  blocks: {
    customBlocks: null,
    builtInBlocks: null,
  },
});

const isPendingAction = (action) => action.type.endsWith(`/pending`);

const isRejectedAction = (action) => action.type.endsWith(`/rejected`);

export const templatesSlice = createSlice({
  name: TEMPLATES_FEATURE_KEY,
  initialState: initialTemplatesState,
  reducers: {
    add: templatesAdapter.addOne,
    remove: templatesAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTemplates.fulfilled, (state, { payload }) => {
        payload.data.reduce((acc, template) => {
          template.blocks = (template.blocks || []).map((block) => {
            if (block.etlFunction === 'oe-kit-2022-etl') {
              return {
                ...block,
                variables: {
                  file: { url: '', name: '' },
                  formFields: [
                    {
                      content: '',
                      defaultValue: '',
                      name: 'medicalContributionEE',
                      label: 'Medical Contribution (EE)',
                      required: true,
                      type: 'number',
                      _id: '1',
                    },
                    {
                      content: '',
                      defaultValue: '',
                      name: 'medicalContributionDependent',
                      label: 'Medical Contribution (Dependent)',
                      required: true,
                      type: 'number',
                      _id: '5werwer',
                    },
                    {
                      content: '',
                      defaultValue: '',
                      name: 'employerContributionType',
                      label: '',
                      required: true,
                      options: [
                        {
                          value: 'employerContributionEvenAcrossPlans',
                          label: 'Employer contribution even across all plans',
                        },
                        {
                          value: 'employerContributionBasedOnTheLowestPlan',
                          label:
                            'Employer contribution based on the contribution to the lowest plan offered',
                        },
                      ],
                      type: 'radio',
                      _id: '9',
                    },
                  ],
                },
              };
            }

            return block;
          });

          return acc;
        }, []);
        templatesAdapter.setAll(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchSingleTemplate.fulfilled, (state, { payload }) => {
        templatesAdapter.upsertOne(
          state,
          addDocumentVariablesBlock(payload.data),
        );
        // templatesAdapter.upsertOne(state, payload.data);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deleteTemplate.fulfilled, (state, { meta }) => {
        templatesAdapter.removeOne(state, meta.arg.templateId);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateTemplate.fulfilled, (state, { payload }) => {
        templatesAdapter.upsertOne(
          state,
          addDocumentVariablesBlock(payload.data),
        );
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateBlock.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.blocks.customBlocks = payload.data;
        }
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(createBlock.fulfilled, (state, { payload }) => {
        state.blocks.customBlocks = payload.data;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(cloneTemplate.fulfilled, (state) => {
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchBlocks.fulfilled, (state, { payload }) => {
        state.blocks = payload;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addMatcher(isPendingAction, (state, action) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addMatcher(isRejectedAction, (state, { error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

const { selectAll, selectEntities, selectById } =
  templatesAdapter.getSelectors();
export const getTemplatesState = (rootState) =>
  rootState[TEMPLATES_FEATURE_KEY];

export const templatesReducer = templatesSlice.reducer;
export const templatesActions = templatesSlice.actions;
export const templateSelectors = {
  selectAll: createSelector(getTemplatesState, selectAll),
  selectAllEntities: createSelector(getTemplatesState, selectEntities),
  selectAllTemplatesNames: () =>
    createSelector(getTemplatesState, (state) => selectAll(state).map(template => template.name)),
  selectById: (templateId) =>
    createSelector(getTemplatesState, (state) => selectById(state, templateId)),
  selectByIds: (templateIds) =>
    createSelector(getTemplatesState, ({ ids, entities }) =>
      (templateIds || []).reduce((acc, templateId) => {
        const template = entities[templateId];

        if (template) {
          acc.push(template);
        }

        return acc;
      }, []),
    ),
};
export const blockSelectors = {
  selectAll: createSelector(getTemplatesState, (state) => state.blocks),
};
